<template>
  <div class="importEquipment">
    <div v-if="types=='oo' || types=='LM'" class="boxs">
      <div>
        <p>右眼</p>
        <ul>
          <li></li>
          <li>时间</li>
          <li class="rightb">球面</li>
          <li class="rightb">散光</li>
          <li>轴位</li>
          <li></li>
        </ul>
      </div>
      <div>
        <p style="width: 3.4rem">左眼</p>
        <ul>
          <li class="rightb">球面</li>
          <li class="rightb">散光</li>
          <li>轴位</li>
          <li class="tj">瞳距</li>
          <li></li>
        </ul>
      </div>
    </div>
    <div v-if="types=='oo' || types=='LM'" class="boxs nrs">
      <div style="display: flex; justify-content: center; height: 40px;" v-for="(item, idx) in datas">
        <ul>
          <li>{{idx+1}}</li>
          <li>{{item.RecTimeString}}</li>
          <li class="bo2p2">{{item.RightSPH}}</li>
          <li class="bo2p2">{{item.RightCYL}}</li>
          <li>{{item.RightAxis}}</li>
          <li></li>
        </ul>
        <ul >
          <li  class="bo2p">{{item.LeftSPH}}</li>
          <li  class="bo2p">{{item.LeftCYL}}</li>
          <li style="position: relative; left: .11rem">{{item.LeftAxis}}</li>
          <li class="tj" style="position: relative; left: .11rem">{{item.PD}}</li>
          <li><el-button @click="dr(idx)">导入</el-button></li>
        </ul>
      </div>
    </div>

    <div v-if="types == 'ct'">
      <div class="yy">
        <p>右眼</p>
        <p>左眼</p>
      </div>
      <div class="nrboxct">
        <div  v-for="(item, idx) in datas" class="nrboxct-a">
          <div class="nrct">
            <div>{{idx+1}}</div>
            <div>{{item.RightCT}}</div>
            <div></div>
          </div>
          <div class="nrct">
            <div></div>
            <div>{{item.LeftCT}}</div>
            <div><el-button @click="dr(idx)">导入</el-button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fn from "@/publicFn/baseFn.js"
export default {
  name: "importEquipment",
  data() {
    return {
      datas: []
    }
  },
  props: {
    types: {
      default: 'oo'
    }
  },
  created() {
    this._api.eyeTest.getImportEQ(this.types)
    .then(res => {
      // console.log(res)
      if (res.GetListResult && res.GetListResult.length) {
        this.datas = res.GetListResult
        // console.log(22222)
        // console.log(this.datas)
        if (window.androids) {
          if (1) {
            let str = window.androids.getComputerOptometry()
            if (str) {
              str = JSON.parse(str)
              let d = new Date()
              this.datas.unshift({
                RecTimeString: fn.getTime(0) + " " + d.getHours() + ":" + d.getMonth() + ":" + d.getSeconds(),
                RightSPH: fn.add0(str.sphere_right*0.125),
                RightCYL: fn.add0(str.cylinder_right*0.125),
                RightAxis: fn.add0(str.axis_right),
                PD: fn.add0(str.pd_right/10+str.pd_left/10),
                LeftSPH: fn.add0(str.sphere_left*0.125),
                LeftCYL: fn.add0(str.cylinder_left*0.125),
                LeftAxis: fn.add0(str.axis_left),
              })
              this.postData.S_OP_Json.S_OP_R_SC_DSOD = fn.add0(str.sphere_right*0.125)
              this.postData.S_OP_Json.S_OP_R_SC_DSOS = fn.add0(str.sphere_left*0.125)

              this.postData.S_OP_Json.S_OP_R_SC_DCOD = fn.add0(str.cylinder_right*0.125)
              this.postData.S_OP_Json.S_OP_R_SC_DCOS = fn.add0(str.cylinder_left*0.125)

              this.postData.S_OP_Json.S_OP_R_SC_XOD = fn.add0(str.axis_right)
              this.postData.S_OP_Json.S_OP_R_SC_XOS = fn.add0(str.axis_left)

              this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OD = fn.add0(str.pd_right/10)
              this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OS = fn.add0(str.pd_left/10)
            }else {
              this.$alert('获取设备数据失败请稍后再试', '提示')
            }
          }
          // if (id == 2) {
          //   let str = window.androids.getSubjectiveOptometry()
          //   if (str) {
          //     str = JSON.parse(str)
          //     this.postData.S_OP_Json.S_OP_R_SS_DSOD = fn.add0(str.sphere_right*0.125)
          //     this.postData.S_OP_Json.S_OP_R_SS_DSOS = fn.add0(str.sphere_left*0.125)
          //
          //     this.postData.S_OP_Json.S_OP_R_SS_DCOD = fn.add0(str.cylinder_right*0.125)
          //     this.postData.S_OP_Json.S_OP_R_SS_DCOS = fn.add0(str.cylinder_left*0.125)
          //
          //     this.postData.S_OP_Json.S_OP_R_SS_XOD = fn.add0(str.axis_right)
          //     this.postData.S_OP_Json.S_OP_R_SS_XOS = fn.add0(add0str.axis_left)
          //
          //     this.postData.S_OP_Json.S_OP_R_SS_AddOD = fn.add0(str.add_right*0.125)
          //     this.postData.S_OP_Json.S_OP_R_SS_AddOS = fn.add0(str.add_left*0.125)
          //
          //     this.postData.S_OP_Json.S_OP_R_SS_PDOD = fn.add0(str.pd_right/10)
          //     this.postData.S_OP_Json.S_OP_R_SS_PDOS = fn.add0(str.pd_left/10)
          //
          //
          //
          //     if (str.isFarMode) {
          //       this.postData.S_OP_Json.S_OP_R_SS_DVAOD = (str.va_right).toString()
          //       this.postData.S_OP_Json.S_OP_R_SS_DVAOS = (str.va_left).toString()
          //     }else {
          //       this.postData.S_OP_Json.S_OP_R_SS_NVAOD = (str.va_right).toString()
          //       this.postData.S_OP_Json.S_OP_R_SS_NVAOS = (str.va_left).toString()
          //     }
          //
          //     return []
          //
          //   }else {
          //     this.$alert('获取设备数据失败请稍后再试', '提示')
          //   }
          // }
        }
      }
    })
  },

  methods: {
    dr(idx) {
      this.$emit('getdata', this.datas[idx])
    }
  }
}
</script>

<style scoped lang="scss">
   .importEquipment {
     width: 100%;
     height: 50vh;
     overflow: hidden;
     overflow-y: auto;
   }
   .boxs {
     display: flex;
     justify-content: center;
     div {
       p {
         font-size: 18px;
         font-weight: bold;
         padding-bottom: 15px;
       }
     }
     ul {
       display: flex;
       justify-content: start;
       align-items: center;
       height: 40px;
       li {
         width: 1.1rem;
         font-size: 16px;
         padding: 3px 0;
         //height: 40px;
         text-align: center;
       }
     }
     .rightb{border-right: 2px solid #7f7e7e}
   }
   .nrs {
     height: 40vh;
     overflow-y: auto;
     display: block !important;
     .box-a {padding-top: 10px;}
     div {padding: 10px 0;}
     .bo2p {margin-left: .08rem; width: 1rem;}
     .bo2p2 {margin-left: .05rem}
   }
   .tm {
     opacity: 0;
   }
   .yy {
     display: flex;
     justify-content: center;
     p{
       width: 6rem;
       font-size: 18px;
       font-weight: bold;
       padding-bottom: 15px;
     }
   }
   .nrboxct {
     height: 40vh;
     overflow: hidden;
     overflow-y: auto;
   }
   .nrct {
     div {width: 2rem;}
     display: flex;
     justify-content: center;
   }
   .nrboxct-a {
     display: flex;
     justify-content: center;
     padding: 10px 0;
     font-size: 16px;
   }
</style>
